
export const NUM_OF_BLINDS = 90; // better to use multiple of WIDTH_MAX

export const OPACITY_INIT = 0;

export const OPACITY_MAX = 0.40;

export const OPACITY_MIN = 0.30;

export const DURATION_INIT = 5000;

export const BLIND_WIDTH = 1;

export const FLUCTUATION_MAX = 6;

export const FLUCTUATION_MIN = 2;

export const PERIOD_MAX = 10000;

export const PERIOD_MIN = 4000;

export const MAIN_CONTAINER_BG = "rgb(200, 200, 200)";