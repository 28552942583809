import React from 'react';
import PropTypes from 'prop-types';
import { random, randomInt, wait } from '../helper';
import { FLUCTUATION_MAX, FLUCTUATION_MIN, PERIOD_MAX,
		 PERIOD_MIN, OPACITY_MAX, OPACITY_MIN } 
		from '../config'
import './Blind.css';

class Blind extends React.Component {
	constructor(props) {
		super(props);

		// set initial states
		const {xPos, opacity, period, width} = this.props;

		this.state = {
			width: width,
			xPos: xPos,
			opacity: opacity,
			period: period / 1000,
		}
	}

	manageState = async () => {
		let newXOffset = random(FLUCTUATION_MIN, FLUCTUATION_MAX);
		let newPolarity = randomInt(0, 1) ? 1 : -1;
		let newPeriod = random(PERIOD_MIN, PERIOD_MAX);
		let newOpacity = random(OPACITY_MIN, OPACITY_MAX);
		if ((this.state.xPos + (newPolarity * newXOffset)) > (this.props.maxXPos - this.state.width) ||
			 (this.state.xPos + (newPolarity * newXOffset)) < this.props.minXPos) {
			this.setState({
				xPos: this.state.xPos + (-1 * newPolarity * newXOffset),
				period: newPeriod / 1000,
				opacity: newOpacity,
			})
		} else {
			this.setState({
				xPos: this.state.xPos + (newPolarity * newXOffset),
				period: newPeriod / 1000,
				opacity: newOpacity,
			})
		}
		await wait(newPeriod)
		this.manageState();
	}

	startAnimation = async () => {
		await wait(2000);
		this.manageState();
	}

	componentDidMount() {
		this.startAnimation();
	}

	render() {
		const {xPos, width, period, opacity} = this.state;
		return (
			<div className="blinds" 
				style={{
					left: 0,
					width: `${width}vw`,
					transform: `translateX(${xPos}vw)`,
					transition: `all ${period}s ease`,
					opacity: opacity,
			  }}>
			</div>
		);
	}

}

Blind.propTypes = {
	xPos: PropTypes.number.isRequired,
	minXPos: PropTypes.number,
	maxXPos: PropTypes.number.isRequired,
	opacity: PropTypes.number.isRequired,
	period: PropTypes.number.isRequired,
};

Blind.defaultProps = {
	minXPos: 0,
}

export default Blind;