export const random = (min, max) => {
	// rolling min and max have halve the chance as others
	return Math.random() * (max - min) + min;
}

export const randomInt = (min, max) => {
	// evenly distributed: Math.floor(Math.random() * (max - min +1)) + min
	return Math.floor(Math.random() * (max - min +1)) + min
}

export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));