import React from 'react';
import PropTypes from 'prop-types';
import './Frame.css';

class Frame extends React.Component {
	constructor(props) {
		super(props);
		// do something
	}

	render() {
		let reverseClass = this.props.reversed ? "reversed" : "";
		let gridArea = { gridArea: this.props.gridArea }
		let horizontal = { width: `${this.props.progress}%` }
		let vertical = { height: `${this.props.progress}%` }
		let style;
		if (this.props.direction === "horizontal") {
			style = {...gridArea, ...horizontal};
		} else if (this.props.direction === "vertical") {
			style = {...gridArea, ...vertical};
		}
		return (
			<div className={`frame ${this.props.direction} ${reverseClass}`} style={style}>
			</div>
		);
	}
}

Frame.propTypes = {
	direction: PropTypes.string.isRequired,
	gridArea: PropTypes.string.isRequired,
	progress: PropTypes.number.isRequired,
	reversed: PropTypes.bool,
};

Frame.defaultProps = {
	reversed: false,
}

export default Frame;