import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { NUM_OF_BLINDS, OPACITY_INIT, DURATION_INIT,
		 BLIND_WIDTH, MAIN_CONTAINER_BG } 
		from './config'
import { wait } from './helper';
import Blind from './components/Blind';
import Frame from './components/Frame';

class App extends Component {

	constructor() {
		super();

		this.wrapper = React.createRef()
		this.logo = React.createRef()

		this.state = {
			blinds: [],
			wProgress: 0,
			hProgress: 0,
			mainOpacity: 0,
			mainContainerBgColor: "rgb(20, 20, 20)",
			subOpacity: 0,
		}
	}

	animateFrames = async () => {
		this.setState({
			wProgress: 100,
			hProgress: 100,
		});
		return wait(2000);
	}

	fadeInLogo = async () => {
		this.setState({
			mainOpacity: 1,
		})
		return wait(1000);
	}

	fadeInText = async () => {
		this.setState({
			subOpacity: 1,
		});
		return wait(1000);
	}

	createBlinds = async () => {
		let blindsArr = []
		let logoWidth = this.logo.current.offsetWidth / this.wrapper.current.offsetWidth * 100;

		let separation = (logoWidth - 1) / NUM_OF_BLINDS;
		for (let i = 0; i < NUM_OF_BLINDS; i++) {
			blindsArr.push(<Blind xPos={separation * i} width={BLIND_WIDTH} opacity={OPACITY_INIT} period={DURATION_INIT} maxXPos={logoWidth} />)
		}
		this.setState({
			blinds: blindsArr,
		});
		this.setState({
			mainContainerBgColor: MAIN_CONTAINER_BG,
		});
		return wait(3000);
	}

	startAnimation = async () => {
		// https://stackoverflow.com/questions/38975138/is-using-async-in-settimeout-valid
		await wait(100);
		await this.animateFrames();
		await this.fadeInLogo();
		await this.createBlinds();
		await this.fadeInText();
	}

	componentDidMount() {
		this.startAnimation();
	}

	componentWillUnmount() {
	}

	render() {
		let { wProgress, hProgress, mainOpacity, mainContainerBgColor, subOpacity } = this.state;
		return (
			<div ref={this.wrapper} className="wrapper">
				<Frame direction="horizontal" gridArea="frame1" progress={wProgress} />
				<Frame direction="vertical" gridArea="frame2" progress={hProgress} />
				<div className="main1" style={{ opacity: mainOpacity }}>
					<img ref={this.logo} className="logo" src={logo} alt="WSQ" />
					<div className="bilndContainer" style={{ backgroundColor: mainContainerBgColor }}>
						{this.state.blinds}
					</div>
				</div>
				<div className="main2" style={{opacity: subOpacity}}>
					<span className="title">Wai Si Qi</span>
					<span className="subtitle">System Analyst</span>
					<span className="subtitle">Varmeego Limited</span>
				</div>
				<Frame direction="horizontal" gridArea="frame3" progress={wProgress} reversed={true} />
				<Frame direction="vertical" gridArea="frame4" progress={hProgress} reversed={true} />
			</div>
		);
	}
}

export default App;
